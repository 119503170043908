import React from "react";
import { graphql } from "gatsby";
import styled from "styled-components";
import AudioPlayer from "../components/AudioPlayer";
import BottomNav from "../components/BottomNav";

const TitleStyles = styled.div`
  margin: 0;
  padding-left: 5vw;
  padding-right: 5vw;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
  .notop {
    margin-top: 0;
  }
`;

export default function title({ data: { intro } }) {
  return (
    <TitleStyles>
      <h3 className="nobottom mark">सनातन गुरु येशु के द्वारा</h3>
      <h1 className="mark">अंधेरे से उजाले की ओर</h1>
      <AudioPlayer url={intro.audio.asset.url} />
      <p className="quote nobottom">
        येशु ने कहा, "संसार की ज्योति मैं हूँ। जो मेरा अनुसरण करता है, वह
        अन्‍धकार में कभी नहीं चलेगा वरन् वह जीवन की ज्योति प्राप्त करेगा।"
      </p>
      <p className="ref quote">(योहन 8:12)</p>
      <p className="poetry nobottom">असतो मा सदगमय।</p>
      <p className="poetry nospace">तमसो मा ज्योतिर्गमय।</p>
      <p className="poetry nospace">मृत्योर्मा अमृतं गमय।</p>
      <p className="nobottom">मुझे असत्य से सत्य की ओर ले चल।</p>
      <p className="nospace">मुझे अंधकार से ज्योति की ओर ले चल।</p>
      <p className="notop">मुझे मृत्यु से अमृतम की ओर ले चल।</p>
      <BottomNav current={-1} />
    </TitleStyles>
  );
}

export const query = graphql`
  query {
    intro: sanityIntro(name: { eq: "title" }) {
      id
      audio {
        asset {
          url
        }
      }
    }
  }
`;
